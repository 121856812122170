const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyCkHWcq9zGITCh_2ddWWgn2CVvtWkTZAoQ',
  authDomain: 'ostium-ne.firebaseapp.com',
  databaseURL: 'https://ostium-ne.firebaseio.com',
  projectId: 'ostium-ne',
  storageBucket: 'ostium-ne.appspot.com',
  messagingSenderId: '800653122843',
  appId: '1:800653122843:web:bf4fb52e5b12e6ff3e07be',
  measurementId: 'G-XWW2MFNX9X',
  isProd: true,
  isDev: false,
  isTest: false,
};

export default FIREBASE_CONFIG;
