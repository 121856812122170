import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import './NeTheme.css';

//import useMediaQuery from '@mui/material/useMediaQuery';

export default function NeTheme(props: any) {
  //const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = createTheme({
    palette: {
      //mode: prefersDarkMode ? 'dark' : 'light',
      primary: { main: '#71389c' },
      //dark purple
      secondary: { main: '#a882c4' },
      // lilac
      error: { main: '#FFFFFF' },
      // white
      warning: { main: '#c0c0c0' },
      //darker grey
      info: { main: '#000000' },
      //black
      success: { main: '#e6e6e6', light: '#474545', dark: '#f2f2f2' },
      //lighter grey (main), dark grey (light), lightest grey (darK)
    },
  });

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
