import React, { Suspense } from 'react';
import Layout from './src/components/Layout';
import NeTheme from './src/components/NeTheme';
import ShopProvider from './src/components/ShopProvider';
import FirebaseProvider from './src/components/FirebaseProvider';
import UserProvider from './src/components/UserProvider';

export function wrapRootElement({ element }) {
  return (
    <React.StrictMode>
      <Suspense>
        <ShopProvider>
          <UserProvider>
            <NeTheme>{element}</NeTheme>
          </UserProvider>
        </ShopProvider>
      </Suspense>
    </React.StrictMode>
  );
}

export function onInitialClientRender() {
  window.addEventListener(
    'popstate',
    () => (window.location.href = window.location.href)
  );
}
