import React from 'react';
import Shop from '../shop';

export const ShopContext = React.createContext({});

interface ShopProviderProps {
  children: React.ReactNode;
}

export default function ShopProvider(props: ShopProviderProps) {
  const [shop, setShop] = React.useState<Shop>(new Shop());

  shop.setCallback(() => {
    setShop(shop.copy());
  });

  React.useEffect(
    () => {
      (async () => {
        if (!shop.cart) {
          await shop.loadOrCreateCart();
        }
        shop.notify();
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <ShopContext.Provider value={shop}>{props.children}</ShopContext.Provider>
  );
}
