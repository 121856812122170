export const PLATFORM: any = {
  vi: {
    name: 'Legio',
    shortDesc: '6HP multi- platform',
    desc: 'Legio is a stereo oscillator/effects platform. Turn your module into a unique dynamics processor, a stereo multi-algorithm oscillator, or something completely different! Legio is a 6HP transformable powerhouse.',
    shopUrl: 'https://www.noiseengineering.us/pages/world-of-legio',
  },
  av: {
    name: 'Versio',
    shortDesc: '10HP effects platform',
    desc: 'Versio is a shapeshifting effects platform. Turn your Versio into a clocked delay, ADSR-controlled VCA, or something completely different! Forget menu diving. Say hello to a whole new building block for modular synthesis.',
    shopUrl: 'https://www.noiseengineering.us/pages/world-of-versio',
  },

  ui: {
    name: 'Univer Inter',
    shortDesc: '8-output USB/MIDI-to-CV',
    desc: 'Chainable 8-output MIDI to CV converter, and USB MIDI interface. Do you need to do a factory reset? Turn off your UI, hold down the button on the back of the module while you plug it in via USB. Then follow the instructions in the Wizard as if it were any other platform.',
  },

  alia: {
    name: 'Alia',
    shortDesc: '10HP Oscillator platform',
    desc: '10HP Oscillator platform and voice platform. Basimilus is back. Or try Manis Iteritas. Or some additive PM with Debel Iteritas. Forget menu diving. Say hello to a whole new building block for modular synthesis.',
    shopUrl: 'https://www.noiseengineering.us/pages/world-of-alia',
  },
  ej: {
    name: 'Eris Jovis',
    shortDesc: '',
    desc: 'All jacks.',
  },
  fs: {
    name: 'Fractio Solum',
    shortDesc: '',
    desc: '4hp clock divider/multiplier.',
  },
  hc: {
    name: 'Horus Cina',
    shortDesc: '',
    desc: 'Big gate sequencer.',
  },
  jj: {
    name: 'Jam Jam',
    shortDesc: '',
    desc: '4-channel trigger, gate, and clock processor.',
  },

  qt: {
    name: 'Quantus Trajecta',
    shortDesc: '',
    desc: '4-channel envelope generator.',
  },
  on: {
    name: 'Opp Ned',
    shortDesc: 'CV-able arpeggiator',
    desc: 'A four-channel arpeggiator designed for creating simple patterns that can be modified with CV. Edit and save arps and recall them manually or with a CV input to Ptn to change between saved patterns.',
  },
  vv: {
    name: 'Vice Virga',
    shortDesc: '',
    desc: 'sequential switch.',
  },
  xn: {
    name: 'Xer Mixa',
    shortDesc: '',
    desc: 'Analog mixer with digital control with 10 stereo-paired inputs, 3 stereo bus outs, clickless mutes, state save & recall, and MIDI I/O. Do you need to do a factory reset? Follow the instructions in the Wizard as if it were a Versio!',
  },
  ee: {
    name: 'Expando Expandi',
    shortDesc: '',
    desc: 'CV input expander for Xer Mixa. Do you need to do a factory reset? Turn off your case, hold down the button on the back of the module while you plug it in via USB. Then follow the instructions in the Wizard as if it were any other platform.',
  },
  pd: {
    name: 'Gamut Repetitor',
    shortDesc: 'Quad voltage and CV fun',
    desc: 'Quad controllable-random quantized voltage generator and generative CV sequencer',
  },
};

export const FIRMWARE: any = {
  'Ampla Versio': {
    shortDesc: 'Stereo VCA & envelope',
    medDesc:
      'An ADSR-controlled VCA and resonant multimode-filter gate with a stereo bypassable chorus.',
    longDesc:
      'Ampla Versio is an ADSR-controlled VCA and resonant multimode-filter gate with a stereo bypassable chorus. Blend fades between a pure VCA and a VCA/filter combo, behaving similarly to a traditional lowpass gate, but with lowpass, bandpass, and highpass filter settings. The ADSR envelope is CV-able and features a dynamic curve control, creating an expressive and shapable envelope.',
    shopUrl: 'https://noiseengineering.us/products/ampla-versio',
    manualUrl: 'https://manuals.noiseengineering.us/av/',
  },
  'Desmodus Versio': {
    shortDesc: 'Tail-generator reverb',
    medDesc:
      'Our flagship tail-generator reverb to take you from beautiful to an uncanny, nightmarish atmosphere in the twist of a few knobs.',
    longDesc:
      'Desmodus Versio is our flagship reverb. This is a true stereo in/stereo out or mono in/stereo out effect. Less of a room simulator and more of a synthetic tail generator with features designed for sound design and performance, the parameters on Desmodus Versio allow you to take the effect from a delay to a beautiful reverb to an uncanny, nightmarish atmosphere in the twist of a few knobs.',
    shopUrl: 'https://noiseengineering.us/products/desmodus-versio',
    manualUrl: 'https://manuals.noiseengineering.us/dv/',
    youTubeUrl: 'https://youtu.be/r-1nQuulP4s',
  },
  'Electus Versio': {
    shortDesc: 'Clocked atmosphere generator',
    medDesc:
      'A spacey clocked delay, a reverb that starts with some synced echoes, or a completely new way to create evolving spaces.',
    longDesc:
      "Not quite a reverb, not quite a delay, Electus is Desmodus's more aggressive clocked counterpart: more distortion, more in-your-face atmospheres, and synced delays for creating your tails in a whole new way. Use it as a spacey clocked delay, as a reverb that starts with some synced echoes, or as a completely new way to create evolving spaces.",
    shopUrl: 'https://noiseengineering.us/products/electus-versio',
    manualUrl: 'https://manuals.noiseengineering.us/ev/',
  },
  'Imitor Versio': {
    shortDesc: 'Experimental delay',
    medDesc:
      'A 12-tap delay designed for experimentation. For when you want to get weird.',
    longDesc:
      'Imitor Versio is a delay designed for experimentation. With 12 delay taps and a slew of parameters designed to make the delay perform in unfathomable ways, Imitor Versio is perfect for creating unusual echoes, experimenting with different delay shapes and patterns, adding vintage flare to an atmosphere, and more.',
    shopUrl: 'https://noiseengineering.us/products/imitor-versio',
    manualUrl: 'https://manuals.noiseengineering.us/iv/',
    youTubeUrl:
      'https://www.youtube.com/watch?v=ciZdXQJlmCo&ab_channel=NoiseEngineering',
  },
  'Lacrima Versio': {
    shortDesc: "The devil's autowah",
    medDesc:
      'Autowah: an envelope follower controlling a high-resonance filter plus distortion and chorus.',
    longDesc:
      "Lacrima Versio features an envelope follower that controls a high-resonance filter. Morph the filter between lowpass, bandpass, and highpass slopes, and Lacrima covers all the sounds you'd expect from a typical wah and more. Route an adjustable distortion pre, post, or pre+post filter for some saturation crunch. Add width and motion (and just a little otherworldly tone) with a stereo chorus. The Mod parameter adds audio-rate modulation and octavizing to your signal.",
    shopUrl: 'https://noiseengineering.us/products/lacrima-versio',
    manualUrl: 'https://manuals.noiseengineering.us/lv/',
    youTubeUrl: 'https://youtu.be/3_3hNLZz-UU',
  },
  'Melotus Versio': {
    shortDesc: 'Granular texturizer',
    medDesc: 'Granular texturizer with filtering, freezing, and clocking.',
    longDesc:
      "Melotus Versio is a stereo texturizer that uses granular processing to transform and reshape sound. It's granular for Doombient. Designed to be played by hand or manipulated within a patch, Melotus can act as everything from a grain delay to a completely transformative texturizer with extensive grain control and manipulation of triggering, direction, and randomness. Audio can be filtered with a lowpass/highpass combo filter that adds haunting shimmers and subharmonics. Plus, grains can be frozen by hand or with triggers and gates, or clocked to create rhythmic effects.",
    shopUrl: 'https://noiseengineering.us/products/melotus-versio',
    manualUrl: 'https://manuals.noiseengineering.us/mv/',
    youTubeUrl: 'https://youtu.be/eUwJMKPQpfc',
  },
  'Polydactyl Versio': {
    shortDesc: 'Multiband dynamics processor',
    medDesc:
      'Three-bands of compression/expansion with limting, saturation, and adjustable noise gate.',
    longDesc:
      'Polydactyl Versio is our take on a multiband dynamics processor. Designed for timbral processing on individual instruments as well as end-of-chain glue, Polydactyl takes inspiration from Librae Legio’s unique and transparent algorithm and applies it across three bands for maximal dynamic impact. Add in the multiband saturation knob, limiter, and noise gate and you have a processor that gives you ultimate control over the color and dynamics of your sound.',
    shopUrl: 'https://noiseengineering.us/products/polydactyl-versio',
    manualUrl: 'https://manuals.noiseengineering.us/pv/',
    youTubeUrl: 'https://youtu.be/SizwbXE6Mt0',
  },
  'Ruina Versio': {
    shortDesc: 'Stereo multi-distortion',
    medDesc:
      'Distortion. All of the distortions. All of them. Yes, that one too.',
    longDesc:
      "Ruina Versio answers the age-old question: how many different types of distortion can we fit on a single module? The answer is a lot, as it turns out. Need a wavefolder? We got a wavefolder. Want some multiband distortion? Of course you do! And that's not even including the DOOM parameter! We also decided that Smoosh was a parameter we needed in our lives: press it and add 128 dB of drive. Yes, you read that right: 128 dB. Why have SOME drive when you can have ALL the drive?",
    shopUrl: 'https://noiseengineering.us/products/ruina-versio',
    manualUrl: 'https://manuals.noiseengineering.us/rv/',
    youTubeUrl: 'https://youtu.be/-k6yPISxxjw',
  },
  'Yester Versio': {
    shortDesc: 'Simple delay',
    medDesc:
      'As simple a delay as we seem to be able to make here at NE. Three taps and perfect for everything.',
    longDesc:
      'Yester Versio is gentler, kinder sibling of Imitor Versio: your everyday, use-it-on-everything delay. Yester Versio is designed to be straightforward to control and easy to use. It’s the perfect background for the other instruments in your patch, with just enough character to stand out if you want it to. With clock sync, tap tempo, and adjustable divisions – plus settings for triplets and dotted timing – it’s easy to make Yester sync to the rest of your patch and create interesting rhythms. If simple echoes aren’t your style, use old to add some grit, or change their pitch and stereo position with the Chorus and Pan controls!',
    shopUrl: 'https://noiseengineering.us/products/yester-versio',
    manualUrl: 'https://manuals.noiseengineering.us/yv/',
    youTubeUrl: 'https://youtu.be/dwOmdgrLmq0',
  },
  'Librae Legio': {
    shortDesc: 'Dynamics processor',
    medDesc:
      "Compression, expansion, limiting, noise gating, and distortion. It's in there.",
    longDesc:
      'Librae Legio is a dynamics processor designed to take a whole patch to the next level. Compression, expansion, limiting, noise gating, and two styles of gentle distortion: Librae is the perfect tool to bring your mix together. And while it can do extreme limiting and really squash your sounds together, we developed it with transparency in mind – whether you want to hard-limit a techno mix or gently bring the sounds in an ambient patch together, Librae is the end-of-chain dynamics processor you’ve been looking for.',
    shopUrl: 'https://noiseengineering.us/products/librae-legio',
    manualUrl: 'https://manuals.noiseengineering.us/ll/',
    youTubeUrl: 'https://youtu.be/WNRty18VE6E',
  },
  'Roucha Legio': {
    shortDesc: 'Multimode filter',
    medDesc:
      'Multimode filter with just the right amount of squelchiness, and CV if you disagree.',
    longDesc:
      'Roucha Legio is a 12dB/octave state-variable stereo multimode filter with settings for lowpass, bandpass, or highpass operation and adjustable filter frequency. Its CV-controllable Resonance parameter perfect for acid basslines and in-your-face filter sweeps. If that’s not enough, the Fold knob adds wavefolding to the signal chain, before, after, or both before and after the filter. Plus, if you like dramatic buildups and DJ-style filter cuts, all processing can be bypassed by tapping the frequency encoder or by sending a gate signal to the module.',
    shopUrl: 'https://noiseengineering.us/products/roucha-legio',
    manualUrl: 'https://manuals.noiseengineering.us/rl/',
    youTubeUrl: 'https://youtu.be/8jzSZls9q04',
  },
  'Sinc Legio': {
    shortDesc: 'Stereo PM oscillator',
    medDesc: 'Multimode stereo oscillator with wavemorphing, folding, and PM.',
    longDesc:
      'Sinc Legio is a simple oscillator with some tricks up its proverbial sleeve. If you need some basic waveforms, Sinc has you covered – and it makes it easy to spice up those waveforms with phase modulation, wavefolding, or hard sync. Sinc pairs well with other oscillators in your system, too: hard-sync and stereo PM inputs make complex sounds easy to patch. Combine broad-ranging timbral parameters with a vintage-inspired chorus, and you’re well on your way to stereo sound-design heaven in just 6 HP.  Sinc has multiple algorithms that give a world of timbral possibility at the flip of a switch. Phase modulation, plucky FM, and bright and heavy modulation await.  Did we put a wavefolder on it? Of course we did. Shape and fold to heart’s content in Fold mode.',
    shopUrl: 'https://noiseengineering.us/products/sinc-legio',
    manualUrl: 'https://manuals.noiseengineering.us/sl/',
    youTubeUrl: 'https://youtu.be/YOsPyC5JuWQ',
  },
  'Tymp Legio': {
    shortDesc: 'Compact drum voice',
    medDesc:
      'Our go-to option for percussion in a small footprint. A drumkit in 6HP.',
    longDesc:
      'Inspired by our love of percussion, we made Tymp Legio as an opportunity to fit the sounds we wanted in a drum kit in 6hp. The modes (Boot, Cat, Boing) are more of an onomatopoetic guide to the sounds each encompasses. Each mode is offset by three octaves, but can be dialed up or down. Toggle the Tang switch to select the flavor within each mode that suits you. The Quack inputs duck your audio to your percussion (or pass through in Boing mode), making Tymp Legio a fantastic performance module.',
    shopUrl: 'https://noiseengineering.us/products/tymp-legio',
    manualUrl: 'https://manuals.noiseengineering.us/tl/',
    youTubeUrl: 'https://youtu.be/pwEW5X8DUvc',
  },
  'Virt Iter Legio': {
    shortDesc: 'Stereo oscillator',
    medDesc:
      'Multi-algorithm versatile oscillator with phase-modulation inputs',
    longDesc:
      'Virt Iter Legio is a three-algorithm stereo oscillator with stereo PM inputs. Its simple interface and unique, immersive sound make it a staple for any style of sound design. Use the hard sync input to add an aggressive edge to sounds when synced to another oscillator, or try patching VIL’s PM inputs independently for even further sonic exploration in the stereo field. Turn on the beautiful, wide, vintage-inspired chorus and you’ll have an endless supply of beautiful sounds.',
    shopUrl: 'https://noiseengineering.us/products/virt-iter-legio',
    manualUrl: 'https://manuals.noiseengineering.us/vil/',
    youTubeUrl: 'https://youtu.be/lEUll05FRds',
  },
  'Univer Inter': {
    shortDesc: 'MIDI to CV converter',
    medDesc:
      'Do you need to do a factory reset? Turn off your UI, hold down the button on the back of the module while you plug it in via USB. Then, follow the instructions in the Wizard.',
    longDesc:
      'Chainable 8-output MIDI to CV converter, and USB MIDI interface. Do you need to do a factory reset? Turn off your UI, hold down the button on the back of the module while you plug it in via USB. Then, follow the instructions in the Wizard.',
    shopUrl: 'https://noiseengineering.us/products/univer-inter',
    manualUrl: 'https://manuals.noiseengineering.us/ui/',
    youTubeUrl: 'https://youtu.be/pPq-m6smOnY?si=eFXo-DXlO52jt_Zi',
  },
  'Xer Mixa': {
    shortDesc: '32 HP Mixer',
    medDesc:
      'Analog mixer with digital control with 10 stereo-paired inputs, 3 stereo bus outs, clickless mutes, state save & recall, and MIDI I/O. Do you need to do a factory reset? Follow the instructions in the Wizard as if it were a Versio!',
    longDesc:
      'Analog mixer with digital control with 10 stereo-paired inputs, 3 stereo bus outs, clickless mutes, state save & recall, and MIDI I/O. Do you need to do a factory reset? Follow the instructions in the Wizard as if it were a Versio!',
    shopUrl: 'https://noiseengineering.us/products/xm?color=black',
    manualUrl: 'https://manuals.noiseengineering.us/xm/',
    youTubeUrl: 'https://www.youtube.com/watch?v=QYPMTWON7iY',
  },
  'Expando Expandi': {
    shortDesc: 'CV expander',
    medDesc:
      'CV input expander for Xer Mixa. Do you need to do a factory reset? Turn off your system, hold down the button on the back of the module while you plug it in via USB. Then follow the instructions in the Wizard as if it were any other platform.',
    longDesc:
      'CV input expander for Xer Mixa. Do you need to do a factory reset? Turn off your system, hold down the button on the back of the module while you plug it in via USB. Then follow the instructions in the Wizard as if it were any other platform.',
    shopUrl: 'https://noiseengineering.us/products/expando-expandi?color=black',
    manualUrl: 'https://manuals.noiseengineering.us/xm/#expando-expandi',
    youTubeUrl: 'https://www.youtube.com/watch?v=QYPMTWON7iY',
  },
  'Basimilus Iteritas Alia': {
    shortDesc: 'Universal drum synthesizer',
    medDesc: `Universal drum synthesizer with roots in the analog world: a six-oscillator additive and FM synthesizer that can be a whole drum kit, or a bass, or a lead.`,
    longDesc:
      'Basimilus Iteritas Alia is a parameterized digital drum voice with its roots in the analog world. At its heart, the BIA is a simple six-oscillator additive and FM synthesizer with waveform, harmonic spread, and envelope controls. An adjustable noise oscillator and an extreme take on a wavefolder round out its synthesis abilities.',
    shopUrl: 'https://noiseengineering.us/products/basimilus-iteritas-alia',
    manualUrl: 'https://manuals.noiseengineering.us/bia/',
    youTubeUrl: 'https://youtu.be/QWL5qvLvy94',
  },
  'Debel Iteritas Alia': {
    shortDesc: 'Hybrid additive phase-modulation voice',
    medDesc: `Hybrid additive phase-modulation voice inspired by some of our favorite synth sounds across the decades. Have your nachos and eat them too.`,
    longDesc:
      'Debel Iteritas Alia is a hybrid additive phase-modulation voice inspired by some of our favorite synth sounds across the decades. Three synthesis algorithms span classic FM plucks and basses to all-out additive mayhem. Debel Iteritas Alia is a combo meal of a synth voice in a supremely compact package.',
    shopUrl: 'https://noiseengineering.us/products/debel-iteritas-alia',
    manualUrl: 'https://manuals.noiseengineering.us/dia/',
    youTubeUrl: 'https://youtu.be/BMDFeTRDswk?si=7MOjBsf555lkw2LP',
  },
  'Manis Iteritas Alia': {
    shortDesc: 'Dark industrial-strength voice',
    medDesc: `Gross, gritty, and dark industrial-strength voice. Pangolin approved for leads, basslines, drums, darkness, detuned madness, or pure mayhem.`,
    longDesc:
      'Manis Iteritas Alia is a voice based on the architecture of Basimilus. It was designed to be more gritty and aggressive by using only sawtooth waves that are manipulated, modulated, and distorted through a rethought interface. Manis Iteritas Alia is suitable for leads, basslines, drums, darkness, detuned madness, or pure mayhem.',
    shopUrl: 'https://noiseengineering.us/products/manis-iteritas-alia',
    manualUrl: 'https://manuals.noiseengineering.us/mi/',
    youTubeUrl: 'https://youtu.be/n0b9zn3nT_M?si=MAo2pPr9WUFjHE0v',
  },
  'Cursus Iteritas Alia': {
    shortDesc: 'Dynamically generated wavetable oscillator ',
    medDesc: `Multimode VCO with spectral-like controls. CIA has a musical and harmonic character, with a suboscillator output for some oomph and a performable hold button.`,
    longDesc:
      'Cursus Iteritas Alia is a voltage-controlled oscillator that works from a dynamically generated wavetable. It gives the user spectral-like controls over three different modes based on different conceptualizations of frequency. Cursus Iteritas Alia has a musical tone structure that produces an extremely wide variety of harmonic sounds. The suboscillator output is perfect for to adding low-end power to a patch, and the Hold button adds unique performability to a jam.',
    shopUrl: 'https://noiseengineering.us/products/cursus-iteritas-alia',
    manualUrl: 'https://manuals.noiseengineering.us/ci/',
    youTubeUrl: 'https://youtu.be/4xICssDsw0U?si=kouDsvKvkxTxis1t',
  },
  'Incus Iteritas Alia': {
    shortDesc: 'Wavemangling FM percussion and instrument voice',
    medDesc: `Smash big and bold sounds into your patches with IIA. Started as a percussion voice, ended as so much more, IIA can make kicks, hits, snares, FM plucks, basslines, and more.`,
    longDesc:
      'Incus Iteritas Alia smashes big and bold sounds into your patches. Incus started as a percussion voice, and evolved into so much more: create kicks for any genre, snares, and metallic hits, or go melodic and create FM plucks, analog-style basslines, and so much more. Incus uses a combination of waveshaping, saturation, wavefolding, FM, and additive synthesis, plus a slew of internal modulation, all behind easy-to-use parameters perfect for jamming.',
    shopUrl: 'https://noiseengineering.us/products/incus-iteritas-alia',
    manualUrl: 'https://manuals.noiseengineering.us/iia/',
    youTubeUrl: 'https://youtu.be/ybLWknvm8vY?si=BuFBhXi3-26culIu',
  },
  'Ataraxic Iteritas Alia': {
    shortDesc:
      'Unapologetically digital oscillator guaranteed to make the fax machine jealous',
    medDesc: `AIA was designed to be aggressive and unapologetic. `,
    longDesc:
      'Ataraxic Iteritas Alia is a voltage-controlled oscillator based on the original Ataraxic Translatron but beefed up with a suboscillator and performable hold button. Sound starts with one of three bit tables shaped via variable interpolation that is then scrolled through, amplitude modulated, folded, and distorted by the CV-controllable front panel controls. Subby basses, metallic drones, unrelentingly digital timbres, and more are all to be had from the AIA. Plus we added a dedicated suboscillator out to make your patches even more extreme.',
    shopUrl: 'https://noiseengineering.us/products/ataraxic-iteritas-alia',
    manualUrl: 'https://manuals.noiseengineering.us/ai/',
    youTubeUrl: 'https://youtu.be/CRL0IWGeOxc?si=vtw0ZcWkPlVIayJE',
  },
  'Opp Ned': {
    shortDesc: '4-channel CV-able arpeggiator',
    medDesc: `A four-channel arpeggiator designed for creating simple patterns that can be modified with CV. Edit and save arps and recall them manually or with a CV input to Ptn to change between saved patterns. 
    `,
    longDesc: '',
    shopUrl: 'https://noiseengineering.us/products/opp-ned',
    manualUrl: 'https://manuals.noiseengineering.us/on/',
    youTubeUrl: 'https://youtu.be/8JTDr6IttU4',
  },
  'Pax Digitalis': {
    shortDesc: 'Quad voltage generator/generative CV sequencer',
    medDesc: `Quad controllable-random quantized voltage generator and generative CV sequencer`,
    longDesc: '',
    shopUrl: 'https://noiseengineering.us/',
    manualUrl: 'https://manuals.noiseengineering.us/on/',
    youTubeUrl: 'https://youtu.be/8JTDr6IttU4',
    name: 'Gamut Repetitor',
  },
  'Morbus Legio': {
    shortDesc: 'Stereo noise generator',
    medDesc:
      'Gentle-to-harsh noise generator and distortion processor. It’s Morbus time.',
    longDesc:
      'Morbus Legio is a combination of signal processor and noise generator. Morbus features three CV-able parameters that affect how noise is generated, plus a wavefolder and a chorus. You can also use Morbus as a signal processor: soft clipping, wavefolding, FWR distortion, and chorus can all be applied to an external signal, and the internal noise oscillator can be mixed with the output or bypassed.',
    shopUrl: 'https://noiseengineering.us/products/morbus-legio',
    manualUrl: 'https://manuals.noiseengineering.us/ml/',
    youTubeUrl: 'https://youtu.be/Jfhaweutxl8',
  },
};

export const PLUGIN_URL: any = {
  'Manis Iteritas': 'https://manuals.noiseengineering.us/manis_iteritas/',
  'Sinc Vereor': 'https://manuals.noiseengineering.us/sinc_vereor/',
  'Virt Vereor': 'https://manuals.noiseengineering.us/virt_vereor/',
  Ruina: 'https://manuals.noiseengineering.us/ruina/',
  Polydactyl: 'https://manuals.noiseengineering.us/polydactyl/',
  Librae: 'https://manuals.noiseengineering.us/librae/',
  Imitor: 'https://manuals.noiseengineering.us/imitor/',
  'Loquelic Vereor': 'https://manuals.noiseengineering.us/loquelic_vereor/',
  'Basimilus Iteritas':
    'https://manuals.noiseengineering.us/basimilus_iteritas/',
  'Cursus Vereor': 'https://manuals.noiseengineering.us/cursus_vereor/',
  Desmodus: 'https://manuals.noiseengineering.us/desmodus/',
};

export const getPluginUrl = (name: string) => {
  return (
    PLUGIN_URL[name] ||
    'https://manuals.noiseengineering.us/' +
      name.replace(' ', '_').toLowerCase()
  );
};

export const PUBLIC_RELEASE: any = [];

export function findRelease(id: string) {
  for (const x of PUBLIC_RELEASE) {
    if (x.id === id) {
      return x;
    }
  }
  return undefined;
}

export const PANEL_WIDTH = 1000;
// if pitch bend true default 3. If clockDiv true default 1

export const VERIFYEMAILMESSAGE =
  'We have sent you an email with a magic link to verify your account. Please click the link to login.';

//TOOLTIPS
const CATTOOL_OFF = 'Disables output';
const CATTOOL_NOTE = 'Monophonic note outputs like pitch, velocity, and gate';
const CATTOOL_POLY = 'Polyphonic note outputs like pitch, velocity, and gate';
const CATTOOL_CONTROL = 'CC modulation outputs';
const CATTOOL_BEND = 'Pitch bend for high-resoltion CV modulation';
const CATTOOL_SYNC = 'Clock and other timing signals';
const CATTOOL_MPE = 'Polyphonic note outputs for use with MPE controllers';

// Globals
export const DELAYTOOL =
  'The amount of delay between UI receiving a MIDI message and outputting it as voltage';
export const CHANNELTOOL = 'MIDI channel';
export const SHAPETOOL =
  'Sets the shape of the output. For clocking things like sequencers, use Trigger. For modulation, try one of the wave shapes like Triangle or Ramp';
export const STARTOCTTOOL =
  'Start Oct/Start Note set the note and octave respectively that is output as 0v by the UI';
export const STARTNOTETOOL =
  'Start Oct/Start Note set the note and octave respectively that is output as 0v by the UI';
export const BENDAMTTOOL =
  'Amount of pitch bend in semitones. If using Bend outputs for modulation elsewhere, set this to 0 to avoid interference.';
export const CCTOOL = 'Selects the CC that will be output as voltage';
export const FILTEROCTTOOL =
  'If you want this output to only respond to a single MIDI note (useful for drum triggers), use Filter Oct/Filter Note to specify the note';
export const FILTERNOTETOOL =
  'If you want this output to only respond to a single MIDI note (useful for drum triggers), use Filter Oct/Filter Note to specify the note';
export const CLOCKDIVTOOL =
  'Sets the clock division of the output. Options with T are triplets, and D are dotted';
export const COPYTOOL =
  'Save this output to the clipboard, to paste on other outputs';
export const PASTETOOL =
  'Replace this output with the settings saved in the clipboard';
export const LEARNTOOL =
  'Assign CC, channel, and note filters with external controllers.';
export const PRESETSTOOL = '';
export const CHAINEDTOOL =
  'Check this to enable chaining. Two UIs can be chained together to create a 16-output interface. An unused MIDI channel must be selected for chaining data with the dropdown to the right. The config need only be saved to the leader UI.';
export const TOOLTIPTOOL =
  'When checked, tooltips for most items will display. Uncheck to turn them off.';
export const USBMIDITOOL =
  'When checked, MIDI from the USB jack will be output at the MIDI Out jack';
export const MIDITHRUTOOL =
  'When checked, MIDI from the MIDI In jack will be passed through to the MIDI Out jack';
export const TRIGLENTOOL =
  'Sets the pulsewidth in milliseconds for Trigger output types';
export const CLOCKLENTOOL =
  'Sets the pulsewidth in milliseconds for any Sync-category outputs';
export const CHAINCHANTOOL =
  'Sets the MIDI channel used for chaining, if two UI are chained together. Avoid sending MIDI data to this channel if chaining is enabled.';
export const VOICECOUNTTOOL = 'The highest MIDI channel used for MPE.';
export const POLYBENDTOOL =
  'Amount of pitch bend in semitones for polyphonic/MPE outs.';
export const SELECTTOOL =
  'If multiple UI are connected via USB, select the UI to be edited here';
export const PRESETSELECTTOOL = '';
export const LOADDEVTOOL =
  'Loads the current configuration from the selected UI to the editing interface below';
export const SAVEDEVTOOL =
  'Saves the current configuration from the editing interface below to the selected UI';
export const FINDTOOL =
  'Click this to pair the configuration app with your UI or with a MIDI controller to enable MIDI Learn functionality.';
export const LOADFILETOOL =
  'Loads a configuration from a SysEx file to the editing interface below';
export const SAVESYXTOOL =
  'Saves the current configuration from the editing interface below to a SysEx file';
export const SAVEJSONTOOL =
  'Saves the current configuration from the editing interface below to a JSON file';
export const SELECTDEVICETOOL =
  'If multiple UI are connected via USB, select the UI to be edited here';
export const UPDATEBUTTONTOOL = '';

export const UI_BEHAVIORS: any = [
  {
    category: 'Off',
    name: 'Off',
    code: 0,
    active: true,
    align: 338,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0 },
    tooltip: 'Disables output',
    tooltipCategory: CATTOOL_OFF,
  },
  {
    category: 'Note',
    name: 'Pitch',
    code: 1,
    active: true,
    align: 25,
    hasBendScale: true,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: true,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0, scale: 3, offset: 36, filter: 255, channel: 0 },
    tooltip: '1V/octave pitch CV',
    tooltipCategory: CATTOOL_NOTE,
  },

  {
    category: 'Note',
    name: 'Trigger',
    code: 3,
    active: true,
    align: 118,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: true,
    hasStart: false,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0, channel: 0 },
    tooltip:
      'Trigger pulsewidth is configurable with the Trigger Length dropdown in Globals',
    tooltipCategory: CATTOOL_NOTE,
  },
  {
    category: 'Note',
    name: 'Gate',
    code: 4,
    active: true,
    align: 118,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: true,
    hasStart: false,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0 },
    tooltip: 'Gate signal based on MIDI note length',
    tooltipCategory: CATTOOL_NOTE,
  },
  {
    category: 'Note',
    name: 'Velocity Gate',
    code: 5,
    active: true,
    align: 118,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: true,
    hasStart: false,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0 },
    tooltip:
      'Variable-voltage gate signal, based on note length and velocity. Minimum voltage is 2V.',
    tooltipCategory: CATTOOL_NOTE,
  },
  {
    category: 'Note',
    name: 'Velocity',
    code: 6,
    active: true,
    align: 118,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: true,
    hasStart: false,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0 },
    tooltip: 'CV from note velocity',
    tooltipCategory: CATTOOL_NOTE,
  },
  {
    category: 'Note',
    name: 'Off Velocity',
    code: 2,
    active: true,
    align: 118,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: true,
    hasStart: false,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0 },
    tooltip: 'Note off velocity; based on how quickly a note is released',
    tooltipCategory: CATTOOL_NOTE,
  },
  {
    category: 'Poly',
    name: 'Pitch',
    code: 7,
    active: true,
    align: 25,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: true,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0, channel: 0, scale: 3, offset: 36, filter: 255 },
    tooltip: '1V/octave pitch CV',
    tooltipCategory: CATTOOL_POLY,
  },

  {
    category: 'Poly',
    name: 'Trigger',
    code: 9,
    active: true,
    align: 338,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0, channel: 0 },
    tooltip:
      'Trigger pulsewidth is configurable with the Trigger Length dropdown in Globals',
    tooltipCategory: CATTOOL_POLY,
  },
  {
    category: 'Poly',
    name: 'Gate',
    code: 10,
    active: true,
    align: 338,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0, channel: 0 },
    tooltip: 'Gate signal based on MIDI note length',
    tooltipCategory: CATTOOL_POLY,
  },
  {
    category: 'Poly',
    name: 'Velocity Gate',
    code: 11,
    active: true,
    align: 338,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0, channel: 0 },
    tooltip:
      'Variable-voltage gate signal, based on note length and velocity. Minimum voltage is 2V.',
    tooltipCategory: CATTOOL_POLY,
  },
  {
    category: 'Poly',
    name: 'Velocity',
    active: true,
    code: 12,
    align: 338,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0, channel: 0 },
    tooltip: 'CV from note velocity',
    tooltipCategory: CATTOOL_POLY,
  },
  {
    category: 'Poly',
    name: 'Off Velocity',
    code: 8,
    active: true,
    align: 338,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0, channel: 0 },
    tooltip: 'Note off velocity; based on how quickly a note is released',
    tooltipCategory: CATTOOL_POLY,
  },
  {
    category: 'Poly',
    name: 'Aftertouch',
    code: 16,
    active: true,
    align: 338,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0, channel: 0 },
    tooltip:
      'Polyphonic continuous note pressure. Not all controllers support aftertouch.',
    tooltipCategory: CATTOOL_POLY,
  },
  {
    category: 'Poly',
    name: 'Decay',
    code: 35,
    active: false,
    align: 55,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: true,
    hasStart: false,
    hasChannel: true,
    hasShape: true,
    usedInChained: true,
    default: { delay: 0, offset: 3, channel: 0 },
    tooltip: 'XXX',
    tooltipCategory: CATTOOL_CONTROL,
    hasMinLength: false,
    hasMaxLength: false,
    hasMinVelocity: false,
  },
  {
    category: 'MPE',
    name: 'Pitch',
    code: 26,
    active: true,
    align: 118,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: true,
    hasChannelMin: false,
    hasChannelMax: false,
    usedInChained: true,
    default: {
      delay: 0,
      scale: 3,
      offset: 36,
      filter: 1,
      volume: 16, //max channel
      channel: 0xff, //channel all (hidden)
      smooth: 2, //min channel
    },
    tooltip: 'MPE pitch output. Polyphonic, and responds to MPE pitch bend.',
    tooltipCategory: CATTOOL_MPE,
  },

  {
    category: 'MPE',
    name: 'Slide',
    code: 27,
    active: true,
    align: 228,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: false,
    hasChannelMin: false,
    hasChannelMax: false,
    usedInChained: true,
    default: { delay: 0, smooth: 2, volume: 16, channel: 0xff },
    tooltip: 'MPE slide controller',
    tooltipCategory: CATTOOL_MPE,
  },
  {
    category: 'MPE',
    name: 'Pressure',
    code: 28,
    active: true,
    align: 228,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: false,
    hasChannelMin: false,
    hasChannelMax: false,
    usedInChained: true,
    default: {
      delay: 0,
      smooth: 2, //channel min
      volume: 16,
      channel: 0xff,
    },
    tooltip: 'MPE pressure controller',
    tooltipCategory: CATTOOL_MPE,
  },
  {
    category: 'MPE',
    name: 'Gate',
    code: 29,
    active: true,
    align: 228,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: false,
    hasChannelMin: false,
    hasChannelMax: false,
    usedInChained: true,
    default: {
      delay: 0,
      smooth: 2, //channel min
      volume: 16,
      channel: 0xff,
    },
    tooltip: 'Gate signal based on MPE note length',
    tooltipCategory: CATTOOL_MPE,
  },
  {
    category: 'MPE',
    name: 'Trigger',
    code: 30,
    active: true,
    align: 228,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannelMin: false,
    hasChannelMax: false,
    hasChannel: false,
    usedInChained: true,
    default: {
      delay: 0,
      smooth: 2, //channel min
      volume: 16,
      channel: 0xff,
    },
    tooltip:
      'Trigger pulsewidth is configurable with the Trigger Length dropdown above',
    tooltipCategory: CATTOOL_MPE,
  },
  {
    category: 'MPE',
    name: 'Velocity',
    code: 31,
    active: true,
    align: 228,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: false,
    hasChannelMin: false,
    hasChannelMax: false,
    usedInChained: true,
    default: {
      delay: 0,
      smooth: 2, //channel min
      volume: 16,
      channel: 0xff,
    },
    tooltip: '',
    tooltipCategory: CATTOOL_MPE,
  },
  {
    category: 'MPE',
    name: 'Velocity Gate',
    code: 32,
    active: true,
    align: 228,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: false,
    hasChannelMin: false,
    hasChannelMax: false,
    usedInChained: true,
    default: {
      delay: 0,
      smooth: 2, //channel min
      volume: 16,
      channel: 0xff,
    },
    tooltip: 'CV from MPE note velocity',
    tooltipCategory: CATTOOL_MPE,
  },
  {
    category: 'MPE',
    name: 'Off Velocity',
    code: 33,
    active: true,
    align: 228,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: false,
    hasChannelMin: false,
    hasChannelMax: false,
    usedInChained: true,
    default: {
      delay: 0,
      smooth: 2, //channel min
      volume: 16,
      channel: 0xff,
    },
    tooltip: 'MPE note off velocity; based on how quickly a note is released',
    tooltipCategory: CATTOOL_MPE,
  },
  {
    category: 'MPE',
    name: 'Decay',
    code: 36,
    active: false,
    align: 55,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: true,
    hasStart: false,
    hasChannel: false,
    usedInChained: true,
    default: {
      delay: 0,
      smooth: 2, //channel min
      volume: 16,
      offset: 3,
      channel: 0xff,
    },
    tooltip: 'XXX',
    tooltipCategory: CATTOOL_CONTROL,
    hasShape: true,
    hasChannelMin: false,
    hasChannelMax: false,
    hasMinLength: false,
    hasMaxLength: false,
    hasMinVelocity: false,
  },
  {
    category: 'Control',
    name: 'CC',
    code: 13,
    active: true,
    align: 228,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: true,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0, filter: 0, channel: 0 },
    tooltip: 'CC modulation output',
    tooltipCategory: CATTOOL_CONTROL,
  },
  {
    category: 'Control',
    name: '14-bit',
    code: 37,
    active: true,
    align: 228,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: true,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0, filter: 0, channel: 0 },
    tooltip: 'CC modulation output',
    tooltipCategory: CATTOOL_CONTROL,
  },
  {
    category: 'Note',
    name: 'Aftertouch',
    code: 15,
    active: true,
    align: 338,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0 },
    tooltip:
      'Monophonic continuous note pressure. Not all controllers support aftertouch.',
    tooltipCategory: CATTOOL_CONTROL,
  },
  {
    category: 'Note',
    name: 'Decay',
    code: 34,
    active: false,
    align: 28,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: true,
    hasStart: false,
    hasChannel: true,
    hasShape: true,
    usedInChained: true,
    default: { delay: 0, smooth: 0, volume: 36, offset: 3 },
    tooltip: 'XXX',
    tooltipCategory: CATTOOL_CONTROL,
    hasMinLength: true,
    hasMaxLength: true,
    hasMinVelocity: true,
  },
  {
    category: 'Bend',
    name: 'All',
    code: 14,
    active: true,
    align: 338,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0 },
    tooltip:
      "Full pitch bend range from 0V to 5V, with 2.5V at center detent. Pitch bend is a high-resolution MIDI message. If you don't need pitch bend for your notes, this is great for modulation of other parameters.",
    tooltipCategory: CATTOOL_BEND,
  },
  {
    category: 'Bend',
    name: 'Up',
    code: 24,
    active: true,
    align: 338,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0 },
    tooltip:
      "Positive pitch bend output as a 0V to 5V signal. Pitch bend is a high-resolution MIDI message. If you don't need pitch bend for your notes, this is great for modulation of other parameters.",
    tooltipCategory: CATTOOL_BEND,
  },
  {
    category: 'Bend',
    name: 'Down',
    code: 25,
    active: true,
    align: 338,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: true,
    usedInChained: true,
    default: { delay: 0 },
    tooltip:
      "Negative pitch bend output as a 0V to 5V signal. Pitch bend is a high-resolution MIDI message. If you don't need pitch bend for your notes, this is great for modulation of other parameters.",
    tooltipCategory: CATTOOL_BEND,
  },
  {
    category: 'Sync',
    name: 'Clock',
    code: 17,
    active: true,
    align: 228,
    hasBendScale: false,
    hasClockDiv: true,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: false,
    usedInChained: false,
    hasShape: true,
    default: { delay: 0, scale: 0x12, offset: 0 },
    tooltip:
      'Clock output based on MIDI clock. Clock trigger pulsewidth can be adjusted with the Clock Length dropdown in Globals. A number of output divisors are available, including musical divisions with triplet (T) and dotted (D) values, and 24 PPQN for high-resolution clock inputs.',
    tooltipCategory: CATTOOL_SYNC,
  },
  {
    category: 'Sync',
    name: 'Gated Clock',
    code: 18,
    active: true,
    align: 226,
    hasBendScale: false,
    hasClockDiv: true,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: false,
    usedInChained: false,
    hasShape: true,
    default: { delay: 0, scale: 0x12 },
    tooltip:
      'Clock output based on MIDI clock. Some MIDI devices constantly output clock, regardless of transport status; this clock stops and starts based on MIDI start and stop messages. Clock trigger pulsewidth can be adjusted with the Clock Length dropdown in Globals. A number of output divisors are available, including musical divisions with triplet (T) and dotted (D) values, and 24 PPQN for high-resolution clock inputs.',
    tooltipCategory: CATTOOL_SYNC,
  },
  {
    category: 'Sync',
    name: 'Start',
    code: 19,
    active: true,
    align: 338,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: false,
    usedInChained: false,
    default: { delay: 0 },
    tooltip:
      'A trigger that is output when a MIDI Start message is received, useful for resetting some sequencers. Trigger pulsewidth can be adjusted with the Clock Length dropdown in Globals.',
    tooltipCategory: CATTOOL_SYNC,
  },
  {
    category: 'Sync',
    name: 'Resume',
    code: 20,
    active: true,
    align: 338,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: false,
    usedInChained: false,
    default: { delay: 0 },
    tooltip:
      'A trigger that is output when a MIDI Resume message is received; these are generally sent when transport is unpaused. Trigger pulsewidth can be adjusted with the Clock Length dropdown in Globals.',
    tooltipCategory: CATTOOL_SYNC,
  },
  {
    category: 'Sync',
    name: 'Stop',
    code: 21,
    active: true,
    align: 338,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: false,
    usedInChained: false,
    default: { delay: 0 },
    tooltip:
      'A trigger that is output when a MIDI Stop message is received, useful for resetting some sequencers. Trigger pulsewidth can be adjusted with the Clock Length dropdown in Globals.',
    tooltipCategory: CATTOOL_SYNC,
    tooltipDelay: DELAYTOOL,
  },
  {
    category: 'Sync',
    name: 'Run',
    code: 22,
    active: true,
    align: 338,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: false,
    usedInChained: false,
    default: { delay: 0 },
    tooltip:
      'A run gate (often used in place of reset triggers on devices that use DIN sync/sync24). It is high when transport is running and low when transport is stopped.',
    tooltipCategory: CATTOOL_SYNC,
  },
  {
    category: 'Sync',
    name: 'Any',
    code: 23,
    active: true,
    align: 338,
    hasBendScale: false,
    hasClockDiv: false,
    hasFilterCC: false,
    hasDelay: true,
    hasNoteFilter: false,
    hasStart: false,
    hasChannel: false,
    usedInChained: false,
    default: { delay: 0 },
    tooltip:
      'A trigger that is output when a MIDI Start, Stop, or Resume message is received. Trigger pulsewidth can be adjusted with the Clock Length dropdown in Globals.',
    tooltipCategory: CATTOOL_SYNC,
  },
];

//whitelist
export const PAGE_LOGIN = 'login';
export const PAGE_TANC = 'tanc';
export const PAGE_EULA = 'eula';
export const PAGE_PRIVACY = 'privacy';
export const PAGE_COOKIES = 'cookies';
export const PAGE_VALIDATE = 'validate';
export const PAGE_RESET = 'reset';
export const PAGE_RESET_CLICK = 'resetclick';
export const PAGE_CONTACT = 'contact';
export const PAGE_SUPPORT = 'support';
export const PAGE_FAQS = 'faqs';
export const PAGE_ARTISTAPP = 'artist';
export const WHITELIST = [
  PAGE_LOGIN,
  PAGE_TANC,
  PAGE_EULA,
  PAGE_COOKIES,
  PAGE_PRIVACY,
  PAGE_VALIDATE,
  PAGE_RESET,
  PAGE_RESET_CLICK,
  PAGE_CONTACT,
  PAGE_SUPPORT,
  PAGE_FAQS,
  PAGE_ARTISTAPP,
];

//not whitelisted
export const PAGE_FIRMWARE = 'firmware';
export const PAGE_PLUGIN = 'plugin';
export const PAGE_UNIVER = 'univer';
export const PAGE_REDEEM = 'redeem';
export const PAGE_REDIRECT = 'redirect';

export const BLACKLIST = [
  PAGE_FIRMWARE,
  PAGE_PLUGIN,
  PAGE_UNIVER,
  PAGE_REDEEM,
  PAGE_REDIRECT,
];

export function isWhitelisted(s: string) {
  return WHITELIST.includes(s);
}

export function isValid(s: string) {
  return WHITELIST.includes(s) || BLACKLIST.includes(s);
}
