import {
  User as FirebaseUser,
  getIdTokenResult,
  IdTokenResult,
} from 'firebase/auth';
import React from 'react';
import FB, {
  getFirmwares,
  getPing,
  getPlatforms,
  getPlugins,
  getStaticFirmware,
  getStaticInstaller,
  Platform,
  Product,
} from '../firebase';

export function match(
  array: any[] | null,
  matchField: string,
  matchValue: string,
  field: string
) {
  if (array) {
    for (const x of array) {
      if (x[matchField] && x[matchField] === matchValue) {
        return x[field];
      }
    }
  }
  return '';
}

interface User {
  valid: boolean;
  verified: boolean;
  user: FirebaseUser | null;
  token: IdTokenResult | null;
  perms: string[];
  lics: string[];
  uid: string | null;
  ping: any;
  firmwares: Product[];
  plugins: Product[];
  installers: Product[];
  platforms: Platform[];
  refresh: () => Promise<void>;
}

interface UserProviderProps {
  children: React.ReactNode;
}

const nullUser = {
  valid: false,
  verified: false,
  user: null,
  token: null,
  perms: [],
  lics: [],
  uid: null,
  ping: {},
  firmwares: getStaticFirmware(),
  plugins: [],
  installers: getStaticInstaller(),
  platforms: getPlatforms(getStaticFirmware()),
  refresh: async () => {},
} as User;

async function newUser(fbu: any, refresh: (fbu: any) => Promise<void>) {
  if (!fbu) {
    console.log('no user logged in', fbu);
    return nullUser;
  }

  if (!fbu.emailVerified) {
    const u = JSON.parse(JSON.stringify(nullUser));
    u.valid = true;
    u.user = fbu;
    console.log('user unverified ', fbu.email);
    return u;
  }

  const token = await getIdTokenResult(fbu, true);
  const perms = (token.claims['permissions'] as string[]) || [];
  const lics = (token.claims['licenses'] as string[]) || [];
  const uid = token.claims['guid'] || fbu.uid;
  const ping = getPing();
  const plugins = getPlugins(lics);
  const firmwares = getFirmwares();

  console.log('got user', uid, fbu.uid, perms, lics);

  return {
    valid: true,
    verified: true,
    user: fbu,
    token: token,
    perms: perms,
    lics: lics,
    uid: uid,
    ping: await ping,
    plugins: (await plugins).plugins,
    firmwares: await firmwares,
    installers: (await plugins).installers,
    platforms: getPlatforms(await firmwares),
    refresh: async () => {
      refresh(fbu);
    },
  } as User;
}

const UserContext = React.createContext(nullUser);

export default function UserProvider(props: UserProviderProps) {
  const [user, setUser] = React.useState<User>(nullUser);

  async function refresh(fbu: any) {
    setUser(await newUser(fbu, refresh));
  }

  React.useEffect(() => {
    FB.auth.onAuthStateChanged(refresh);
  }, []);

  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
}

export const useUser = () => {
  return React.useContext<User>(UserContext);
};
